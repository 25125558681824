.mb--default {
  margin-bottom: $mb-default;
}

.text--sm {
  font-size: $font-size-sm;
}

.text--base {
  font-size: $font-size-base;
}

.text--lg {
  font-size: $font-size-lg;
}

.text--underline-none {
  text-decoration: none;
}

.w--190 {
  width: 190px;
}

.miw--150 {
  min-width: 150px;
}

.miw--180 {
  min-width: 180px;
}

.miw--220 {
  min-width: 220px;
}

.maw--255 {
  max-width: 255px;
}

.maw--285 {
  max-width: 285px;
}

.maw--450 {
  max-width: 450px;
}

.maw--730 {
  max-width: 730px;
}

.maw--none {
  max-width: none;
}

.fill--green {
  fill: #3baa34;
}

.fill--red {
  fill: #dc3545;
}

.bg--white {
  background-color: $light;
}

.bg--gray {
  background-color: #f5f5f5;
}

.bg--dark-gray {
  background-color: $c-gray;
}

.c--orange {
  color: $primary;

  svg {
    fill: $primary;
  }
}

.border-bottom--none {
  border-bottom: none;
}

.position-sticky {
  top: calc(#{$header-height-desktop} + 1rem);
}

@include media-breakpoint-down(sm) {
  .btn--block-sm {
    display: block;
    width: 100%;
  }

  .expand {
    margin-left: calc(0px - $grid-gutter-width / 2);
    margin-right: calc(0px - $grid-gutter-width / 2);
  }
}

@include media-breakpoint-up(lg) {
  .overflow--lg-hidden {
    overflow: hidden;
  }
}

@include media-breakpoint-up(sm) {
  .bg--sm-gray {
    background-color: #f5f5f5;
  }
}