@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600&family=Rubik:wght@400;500;700&display=swap');

body {
  padding-top: $header-height;

  &.show-top-bar {
    padding-top: $header-height + $top-bar-height;
  }

  @include media-breakpoint-up(xl) {
    padding-top: $header-height-desktop;

    &.show-top-bar {
      padding-top: $header-height-desktop + $top-bar-height-desktop;
    }
  }
}

ul,
ol {
  margin-bottom: $paragraph-margin-bottom;
}

a,
input,
textarea,
button {
  transition: color $animation-time $animation-type,
    background-color $animation-time $animation-type,
    border-color $animation-time $animation-type,
    opacity $animation-time $animation-type;
}

img {
  max-width: 100%;
  height: auto;
}

figure img {
  width: 100%;
}

* {
  &,
  &:focus {
    outline: none !important;
  }
}
