/*************************************************
    Custom Variables
**************************************************/

$animation-time:                                  .4s;
$animation-type:                                  ease-in-out;
$animation-default:                               all $animation-time $animation-type;

$section-pt:                                      3rem;
$section-pt-desktop:                              4.5rem;

$top-bar-height:                                  2.125rem;
$top-bar-height-desktop:                          2.5rem;
$header-height:                                   4.5rem;
$header-height-desktop:                           5.5rem;

$mb-default:                                      1.5rem;
$mb-headline:                                     3rem;

$font-size-base-desktop:                          1.125rem;
$font-size-lg-desktop:                            1.25rem;
$font-size-sm-desktop:                            .875rem;

$h1-font-size-desktop:                            1rem * 3;
$h2-font-size-desktop:                            1rem * 2;
$h3-font-size-desktop:                            1rem * 1.75;
$h4-font-size-desktop:                            1rem * 1.5;
$h5-font-size-desktop:                            1rem * 1.25;
$h6-font-size-desktop:                            1rem * 1;

$c-gray:                                          #e9ecef;

/*************************************************
    Bootstrap Variables
**************************************************/

// Colors

$body-bg:                                         #fff;
$body-color:                                      #000;
$border-color:                                    transparentize(#ced4da, .5);
$primary:                                         #fd9843;
$secondary:                                       #0187bc;
$light:                                           #fff;
$dark:                                            #1D1D1C;
$success:                                         #3baa34;

// Grid

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1300px
);

$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1250px
);

$grid-row-columns:                                12;

// Spaces

$paragraph-margin-bottom:                         $mb-default;
$headings-margin-bottom:                          $paragraph-margin-bottom;

// Components

$border-radius:                                   .5rem;
$border-radius-sm:                                .25rem;
$border-radius-lg:                                .75rem;

$box-shadow:                                      0 1px 3px rgba(0, 0, 0, 0.12);
$box-shadow-sm:                                   $box-shadow;
$box-shadow-lg:                                   0 12px 24px rgba(0, 0, 0, 0.24);

// Typography

$font-family-base:                                Inter, Arial, sans-serif;

$font-size-base:                                  1rem;
$font-size-lg:                                    1.125rem;
$font-size-sm:                                    .875rem;

$line-height-base:                                1.625;

$font-weight-lighter:                             200;
$font-weight-light:                               300;
$font-weight-normal:                              400;
$font-weight-bold:                                500;
$font-weight-bolder:                              700;

$headings-font-family:                            Rubik, Arial, sans-serif;
$headings-font-weight:                            $font-weight-bold;
$headings-color:                                  $dark;

$h1-font-size:                                    $font-size-base * 2;
$h2-font-size:                                    $font-size-base * 1.75;
$h3-font-size:                                    $font-size-base * 1.5;
$h4-font-size:                                    $font-size-base * 1.25;
$h5-font-size:                                    $font-size-base * 1.125;
$h6-font-size:                                    $font-size-base * 1;

$hr-border-color:                                 transparentize($dark, .95);
$hr-margin-y:                                     $paragraph-margin-bottom;

// Links

$link-color:                                      $primary;
$link-hover-color:                                $secondary;
$link-decoration:                                 none;
$link-hover-decoration:                           none;

// Buttons

$btn-padding-y:                                   .75rem;
$btn-padding-x:                                   1.25rem;
$btn-font-family:                                 $headings-font-family;
$btn-font-size:                                   $font-size-sm;
$btn-font-weight:                                 $font-weight-bold;
$btn-border-width:                                1px;
$btn-transition:                                  $animation-default;
$btn-border-radius:                               2rem;

$btn-padding-y-sm:                                .625rem;
$btn-padding-x-sm:                                1rem;
$btn-font-size-sm:                                .875rem;
$btn-font-size-lg:                                $font-size-base;
$btn-padding-y-lg:                                .875rem;
$btn-border-radius-lg:                            $btn-border-radius;

// Forms

$label-margin-bottom:                             .25rem;

$input-font-family:                               $font-family-base;
$input-font-size:                                 $font-size-sm;
$input-padding-x:                                 1rem;
$input-padding-y:                                 .727rem;
$input-bg:                                        $light;
$input-color:                                     $body-color;
$input-border-color:                              #ddd;
$input-border-width:                              1px;
$input-box-shadow:                                none;
$input-border-radius:                             $border-radius;
$input-focus-border-color:                        darken($input-border-color, 20);
$input-focus-box-shadow:                          0 0 0 4px #eee;
$input-placeholder-color:                         lighten($body-color, 50);

$form-group-margin-bottom:                        1.25rem;

// Form Check

$form-check-input-width:                          2em;
$form-check-min-height:                           $form-check-input-width;
$form-check-margin-bottom:                        0;
$form-check-input-border:                         1px solid $input-border-color;
$form-check-input-border-radius:                  .25rem;
$form-check-input-focus-box-shadow:               $input-focus-box-shadow;
$form-check-input-checked-color:                  $body-color;
$form-check-input-checked-bg-color:               $input-bg;
$form-check-input-checked-border-color:           $dark;

// Cards

$card-spacer-y:                                   2rem;
$card-spacer-x:                                   2rem;
$card-border-width:                               0;
$card-bg:                                         $light;
$card-cap-bg:                                     transparent;
$card-box-shadow:                                 $box-shadow;

// Breadcrumbs

$breadcrumb-font-size:                            .75rem;

$breadcrumb-padding-y:                            0;
$breadcrumb-padding-x:                            0;
$breadcrumb-item-padding:                         .5rem;

$breadcrumb-margin-bottom:                        $mb-default;

$breadcrumb-bg:                                   transparent;
$breadcrumb-divider-color:                        inherit;
$breadcrumb-active-color:                         $body-color;

// Lists

$list-inline-padding:                             1rem;

// Modal

$zindex-modal-backdrop:                           1110;
$zindex-modal:                                    1120;

$modal-backdrop-bg:                               $dark;
$modal-backdrop-opacity:                          .85;

$modal-md:                                        640px;
$modal-sm:                                        500px;

// Badges

$badge-font-size:                                 .75rem;
$badge-padding-y:                                 .45rem;
$badge-padding-x:                                 .333rem;
$badge-border-radius:                             $border-radius-sm;

// Pagination

$pagination-padding-y:                            .657rem;
$pagination-padding-x:                            .5rem;

$pagination-color:                                #999;
$pagination-bg:                                   transparent;
$pagination-border-color:                         transparent;

$pagination-focus-box-shadow:                     none;

$pagination-hover-color:                          $dark;
$pagination-hover-bg:                             $pagination-bg;
$pagination-hover-border-color:                   $pagination-border-color;

$pagination-active-color:                         $pagination-hover-color;
$pagination-active-bg:                            $pagination-bg;
$pagination-active-border-color:                  $dark;

$pagination-disabled-color:                       transparentize($pagination-color, .5);
$pagination-disabled-bg:                          transparent;
$pagination-disabled-border-color:                transparent;

// Accordion

$accordion-padding-y:                             .75rem;
$accordion-padding-x:                             0;
$accordion-bg:                                    transparent;
$accordion-border-width:                          0;
$accordion-border-color:                          $border-color;
$accordion-border-radius:                         0;

$accordion-body-padding-x:                        0;

$accordion-button-padding-x:                      0;
$accordion-button-color:                          $body-color;
$accordion-button-bg:                             transparent;
$accordion-button-active-bg:                      transparent;
$accordion-button-active-color:                   $body-color;

$accordion-button-focus-border-color:             $border-color;
$accordion-button-focus-box-shadow:               none;

$accordion-icon-width:                            1.25rem ;
$accordion-icon-color:                            $body-color;
$accordion-icon-active-color:                     $body-color;
