@import 'variables';

@import '~bootstrap/scss/bootstrap';
@import "~@fancyapps/fancybox/dist/jquery.fancybox.min.css";

@import 'mixins';

@import 'base';
@import 'layout';
@import 'modules';
@import 'components';
@import 'helpers';
@import 'animations';