/*
  Buttons
*/

.btn {
  position: relative;

  &,
  &:hover,
  &:focus,
  &:active {
    box-shadow: none;
  }

  &-primary,
  &-secondary {
    &,
    &:hover {
      color: $light;
    }
  }

  &-primary {
    background: $primary;

    svg {
      fill: $light;
    }
  }

  &--white {
    color: $dark;
    background: $light;

    &:hover {
      background: $c-gray;
    }

    svg {
      fill: $dark;
    }
  }

  &--gray {
    color: #495057;
    background: #f5f5f5;

    &:hover {
      background: #e0e0e0;
    }

    svg {
      fill: #495057;
    }
  }

  &--dark-gray {
    color: $light;
    background: #444;

    &:hover {
      color: $light;
      background: #111;
    }

    svg {
      fill: $light;
    }
  }

  &--transparent-dark {
    color: $light;
    background: transparentize($dark, 0.6);

    &:hover {
      color: $light;
      background: transparentize($dark, 0.3);
    }

    svg {
      fill: $light;
    }
  }

  &-secondary {
    svg {
      fill: $light;
    }
  }

  &-outline-white {
    &,
    &:hover {
      color: $light;
      border-color: $light;

      svg {
        fill: $light;
      }
    }
  }

  &--orange {
    border-color: #ea7a34;
    color: #fff;
    background-color: #ea7a34;

    &:hover,
    &:focus {
      border-color: #fd9843;
      color: #fff;
      background-color: #fd9843;
    }
  }

  &--outline-orange {
    border-color: #ea7a34;
    color: #ea7a34;
    background-color: transparent;

    &:hover,
    &:focus {
      border-color: #ea7a34;
      color: #fff;
      background-color: #ea7a34;
    }
  }

  &--outline-gray {
    border-color: #999;
    color: #636363;

    svg {
      fill: #636363;
    }

    &:hover {
      color: $dark;
      border-color: $dark;

      svg {
        fill: $dark;
      }
    }
  }

  &--facebook,
  &--google {
    color: $light;

    svg {
      fill: $light;

      &:first-child {
        right: auto;
        left: $btn-padding-x / 1.5;
      }
    }

    &:hover,
    &:focus {
      color: $light;

      svg:first-child {
        transform: translate3d(0, -50%, 0);
      }
    }
  }

  &--facebook {
    border-color: #4267b2;
    background-color: #4267b2;

    &:hover,
    &:active {
      border-color: #34528f;
      background-color: #34528f;
    }
  }

  &--google {
    border-color: #d54c39;
    background-color: #d54c39;

    &:hover,
    &:active {
      border-color: #af3b2c;
      background-color: #af3b2c;
    }
  }

  &--white {
    background: $light;
  }

  &--prev,
  &--next {
    text-align: left;

    svg {
      position: absolute;
      top: 50%;
      width: 1rem;
      max-height: 1rem;
      transform: translate3d(0, -50%, 0);
      transition: $animation-default;
    }

    &.btn-sm {
      svg {
        width: 0.75rem;
        max-height: 0.75rem;
      }
    }

    &.btn-lg {
      svg {
        width: 1.125rem;
        max-height: 1.125rem;
      }
    }
  }

  &--circle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2rem;
    height: 2rem;
    padding: 0;
    border-radius: 100%;

    svg {
      width: 1rem;
      height: 1rem;
    }
  }

  &--square {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 3rem;
    min-height: 3rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;

    &.btn-lg {
      min-width: 3.375rem;
      min-height: 3.375rem;
    }

    svg {
      width: 1.125rem;
      height: 1.125rem;
    }
  }

  &--prev {
    padding-left: 3rem;

    svg {
      left: $btn-padding-x / 1.5;
    }

    &.btn-lg {
      svg {
        left: 1rem;
      }
    }
  }

  &--next {
    padding-right: 3rem;

    svg {
      right: $btn-padding-x / 1.5;
    }

    &.btn-lg {
      svg {
        right: 1rem;
      }
    }
  }

  &[data-card-nav-toggle] svg {
    transform: translate(-50%, -50%) rotate(90deg);
  }

  &-link {
    padding-top: 0;
    padding-bottom: 0;
    font-weight: $font-weight-bold;
    font-family: $headings-font-family;
    font-size: $font-size-sm;
    text-transform: uppercase;
    color: $primary;

    &.btn {
      &--prev {
        padding-left: 1.25rem;
        padding-right: 0;

        svg {
          left: 0;
        }
      }

      &--next {
        padding-right: 1.25rem;
        padding-left: 0;

        svg {
          right: 0;
        }
      }
    }

    svg {
      display: inline-block;
      vertical-align: middle;
      width: 0.75rem;
      height: 0.75rem;
      margin-left: 0.125rem;
      fill: $primary;
      transition: transform $animation-time $animation-type;
    }

    &:hover {
      color: $primary;
      opacity: 0.7;

      svg {
        transform: translate(0.25rem, 0);
        fill: $primary;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    &-primary {
      &:hover {
        background-color: mix(black, $primary, 20);
        opacity: 1;
      }

      &:focus {
        background-color: $primary;
        box-shadow: 0 0 0 4px transparentize($primary, 0.8) !important;
      }

      &:not(:disabled):not(.disabled):active {
        background-color: mix(black, $primary, 20);
      }
    }

    &--prev {
      &:hover {
        svg {
          transform: translate3d(-4px, -50%, 0);
        }
      }
    }

    &--next {
      &:hover {
        svg {
          transform: translate3d(4px, -50%, 0);
        }
      }
    }
  }
}

/*
  Form components
*/

.form-label {
  font-weight: $font-weight-normal;
  font-family: $headings-font-family;
  font-size: $font-size-sm;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  margin-bottom: 0.25rem;

  &--lg {
    padding-left: 0;
    padding-right: 0;
    font-weight: $font-weight-bold;
    font-size: $font-size-base;
  }
}

legend {
  font-size: $font-size-base;
  font-weight: $font-weight-bolder;
}

fieldset {
  margin-bottom: 2rem;
}

.input-group {
  > input[type='hidden'] + .form-control {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }

  > *:last-child {
    margin-left: -1px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  > .btn:last-child {
    border-top-right-radius: $input-border-radius;
    border-bottom-right-radius: $input-border-radius;
  }
}

.inline-form {
  position: relative;

  > .btn {
    position: absolute;
    z-index: 1;
    top: 50%;
    margin: 0;
    padding: 0;
    border: 0;
    background: transparent;
    transform: translate3d(0, -50%, 0);

    svg {
      max-width: 1rem;
      max-height: 1rem;
    }
  }

  &--before {
    > .form-control {
      padding-left: 2.75rem;
    }

    > .btn {
      left: 0.875rem;
    }
  }

  &--after {
    > .form-control {
      padding-right: 2.75rem;
    }

    > .btn {
      right: 0.875rem;
    }
  }

  > button,
  > a {
    &:hover {
      opacity: 0.5;
    }
  }
}

.form-group {
  &--error {
    .form-control {
      border-color: $primary;
      background-color: #f8d7da;
    }

    .form-text {
      color: $primary;
    }
  }

  &--success {
    .form-control {
      padding-right: 2rem;
      border-color: $success;
    }

    svg {
      position: absolute;
      top: 50%;
      right: 1rem;
      width: 1rem;
      height: 1rem;
      fill: $success;
      transform: translate(0, -50%);
    }
  }
}

.form-control {
  position: relative;

  &--square {
    width: 3rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    text-align: center;
  }

  svg {
    position: absolute;
    top: 50%;
    right: $input-padding-x;
    width: 1rem;
    height: 1rem;
    transform: translate(0, -50%);
  }
}

select.form-control {
  padding-right: 40px;
  background-image: url('../img/icons/chevron-down.svg');
  background-position: right 15px center;
  background-repeat: no-repeat;
  text-overflow: '';
  text-indent: 1px;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.form-check {
  &--sm {
    .form-check-input {
      width: 1.714285em;
      height: 1.714285em;
      margin-top: -.05em;
    }
  }

  &--block {
    padding: 0;
    line-height: 1.5;

    .form-check {
      &-input {
        width: 1.5rem;
        height: 1.5rem;
        margin-top: 0.75rem;
        margin-left: 0.75rem;

        &:checked {
          background-color: $dark;
          background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");

          + .form-check-label {
            border-color: $dark;
            background-color: rgba(10, 162, 192, 0.1);
          }
        }
      }

      &-label {
        display: block;
        padding: 0.875rem 1rem 0.75rem 3rem;
        border: 1px solid $input-border-color;
        font-size: 0.75rem;
        border-radius: $border-radius;
        transition: $animation-default;

        &:before,
        &:after {
          top: 0.75rem;
          left: 0.75rem;
          width: 1.5rem;
          height: 1.5rem;
        }

        &:before {
          border-width: 3px;
          border-color: $input-border-color;
        }
      }

      &__title {
        display: flex;
        font-family: $headings-font-family;
        font-weight: $font-weight-bold;
        font-size: $font-size-sm;

        > *:last-child {
          display: inline-block;
          margin-left: auto;
          text-align: right;
        }
      }
    }
  }

  &--payment {
    img {
      display: inline-block;
      height: 24px;

      @include media-breakpoint-down(xs) {
        height: 20px;
      }
    }
  }
}

.form-text {
  font-size: 0.75rem;
  font-family: $headings-font-family;
}

.input-count {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 150px;
  padding: 0.4375rem;
  border: $input-border-width solid $input-border-color;
  border-radius: $input-border-radius;

  &__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 auto;
    width: 2rem;
    height: 2rem;
    margin: 0;
    padding: 0;
    border: 0;
    background-color: #f8f9fa;
    border-radius: $border-radius;

    svg {
      display: block;
      width: 0.75rem;
      height: 0.75rem;
      fill: $body-color;
    }

    &:hover {
      background: $border-color;
    }
  }

  &__value {
    .form-control {
      padding: 0;
      margin: 0;
      border: 0;
      font-size: $font-size-sm;
      font-weight: $font-weight-bold;
      font-family: $headings-font-family;
      text-align: center;
      border-radius: 0;
      box-shadow: none;
    }
  }

  &--sm {
    width: 135px;
    padding: 0.1875rem;
  }
}

.option-letter {
  cursor: pointer;

  input {
    position: absolute;
    left: -9999px;
    width: 1px;
    height: 1px;
    opacity: 0;

    &:checked + span {
      border-color: $dark;
      color: $dark;
    }
  }

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 3rem;
    height: 3rem;
    padding: 0 0.25rem;
    font-size: $font-size-sm;
    font-weight: $font-weight-bold;
    border: 1px solid $border-color;
    border-radius: $input-border-radius;
  }

  &--auto {
    span {
      width: auto;
      min-width: 3rem;
      padding-left: 1.25rem;
      padding-right: 1.25rem;
    }
  }

  &--sm {
    span {
      min-width: 2.5rem;
      height: 2rem;
    }
  }
}

.option-iconbox {
  display: block;
  width: 100%;

  input {
    position: absolute;
    left: -9999px;
    width: 1px;
    height: 1px;
    opacity: 0;

    + .option-iconbox__content {
      cursor: pointer;
    }

    &:checked + .option-iconbox__content {
      border-color: $primary;
      color: $primary;
    }
  }

  &__content {
    position: relative;
    padding: 0.875em 1rem 0.75rem 3.25rem;
    font-size: 0.75rem;
    border: 1px solid #e9ecef;
    border-radius: $border-radius;

    > .icon {
      position: absolute;
      top: 50%;
      left: 1rem;
      width: 1.25rem;
      height: 1.25rem;
      transform: translate(0, -50%);
      fill: #3baa34;
    }
  }

  &__title {
    display: block;
    font-family: $headings-font-family;
    font-weight: $font-weight-bold;
    font-size: $font-size-sm;
  }
}

a.option-iconbox {
  &,
  &:hover,
  &:focus {
    color: $body-color;
  }
}

.option-text {
  cursor: pointer;
  color: $body-color;

  &:hover,
  &--active {
    color: $primary;
  }

  input {
    position: absolute;
    left: -9999px;
    width: 1px;
    height: 1px;
    opacity: 0;

    &:checked + span {
      font-weight: $font-weight-bolder;
    }
  }
}

.input-rate {
  display: flex;
  align-items: center;

  &__stars {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    margin-right: 0.5rem;

    label {
      position: relative;
      display: block;
      margin: 0 0.25rem 0 0;
      cursor: pointer;

      &:hover,
      &:hover ~ label,
      &.active,
      &.active ~ label {
        svg {
          fill: #fd9843;
        }
      }
    }

    svg {
      display: block;
      width: 2rem;
      height: 2rem;
      fill: #ced4da;
    }

    input {
      position: absolute;
      overflow: hidden;
      opacity: 0;
      width: 1px;
      height: 1px;
    }
  }

  &__value {
    font-size: 2rem;
    line-height: 1;
    font-weight: $font-weight-bold;
    font-family: $headings-font-family;
  }
}

/*
  Color Chooser
*/

.color-chooser {
  display: flex;
  margin: 0;
  padding: 0;
  list-style-type: none;

  li {
    margin-right: 0.25rem;
    margin-bottom: 0.25rem;
  }

  a,
  label {
    display: block;
  }

  label {
    cursor: pointer;
  }

  input {
    position: absolute;
    overflow: hidden;
    left: -9999px;
    width: 1px;
    height: 1px;
    opacity: 0;
  }

  .active,
  input:checked + {
    .color-chooser__color {
      box-shadow: inset 0 0 0 1px $light, 0 0 0 1px $dark;
    }
  }

  &__color {
    display: block;
    width: 1rem;
    height: 1rem;
    border-radius: 100%;
  }

  &--lg {
    li {
      margin-right: .5rem;
      margin-bottom: .5rem;
    }

    .color-chooser {
      &__color {
        width: 2.5rem;
        height: 2.5rem;
      }
    }

    .active,
    input:checked + {
      .color-chooser__color {
        box-shadow: inset 0 0 0 2px $light, 0 0 0 1px $dark;
      }
    }
  }
}

/*
  File Upload
*/

.file-upload {
  position: relative;
  display: block;
  text-align: center;
  padding: .5rem 1rem;
  font-size: $font-size-sm;
  font-family: $headings-font-family;
  border: 1px dashed #ced4da;
  border-radius: $border-radius;
  cursor: pointer;

  input {
    position: absolute;
    overflow: hidden;
    width: 1px;
    height: 1px;
    opacity: 0;
  }

  img {
    width: 6.25rem;
    height: 6.25rem;
    object-fit: contain;
  }
}

/*
  Success Icon
*/

.success-icon {
  display: inline-block;
  width: 10em;
  height: 10em;
  font-size: 0.75rem;
  border-radius: 50%;
  border: 1em solid lighten($success, 20%);
  background-color: #fff;
  position: relative;
  overflow: hidden;
  transform-origin: center;
  transform: scale(1);

  &__tip,
  &__long {
    display: block;
    position: absolute;
    height: 1em;
    background-color: lighten($success, 20%);
    border-radius: 1em;
  }

  &__tip {
    width: 2.8em;
    top: 4.3em;
    left: 1.4em;
    transform: rotate(45deg);
    visibility: hidden;
  }

  &__long {
    width: 4.4em;
    transform: rotate(-45deg);
    top: 3.8em;
    left: 2.55em;
    visibility: hidden;
  }
}

.show {
  .success-icon {
    animation: showSuccess 180ms ease-in-out;

    &__tip {
      animation: tipInPlace 300ms ease-in-out;
      animation-fill-mode: forwards;
      animation-delay: 180ms;
    }

    &__long {
      animation: longInPlace 140ms ease-in-out;
      animation-fill-mode: forwards;
      animation-delay: 300ms + 140ms;
    }
  }
}

/*
  Dropdown
*/

.dropdown {
  .btn {
    display: flex;
    align-items: center;
    width: 100%;
    text-align: left;
    font-weight: $font-weight-normal;
    font-size: $font-size-sm;
    border: 1px solid #e9ecef;
    border-radius: $border-radius;

    .icon {
      position: absolute;
      top: 50%;
      right: 1rem;
      width: 0.75rem;
      height: 0.75rem;
      opacity: 0.4;
      transform: translate(0, -50%);
    }
  }

  .color-icon {
    margin: -4px 1rem -4px 0;
  }

  &-menu {
    float: none;
    width: 100%;
    min-width: none;
    padding: 0;
    font-size: $font-size-sm;
    border-color: #e9ecef;
  }

  &-item {
    display: flex;
    align-items: center;
    font-family: $headings-font-family;
    padding: $btn-padding-y $btn-padding-x;

    &:hover,
    &:focus {
      color: $primary;
      background: transparent;
    }
  }
}

/*
  Slider
*/

.noUi {
  &-horizontal {
    height: 4px;
    margin: 14px;

    .noUi {
      &-handle {
        top: -14px;
        width: 32px;
        height: 32px;
        border-color: $border-color;
        box-shadow: none;
        border-radius: 100%;

        &:before,
        &:after {
          top: 8px;
          background: $border-color;
        }

        &:before {
          left: 13px;
        }
      }
    }
  }

  &-target {
    border: 0;
    background: $border-color;
    box-shadow: none;
  }
}

/*
  Titles
*/

.section-title {
  margin-bottom: 2rem;
  font-size: $h3-font-size;

  &--lg {
    font-size: $h2-font-size;
    color: $body-color;
  }

  + .section-description {
    margin-top: -2rem;
  }

  @include media-breakpoint-up(lg) {
    margin-bottom: 2.5rem;
    font-size: $h1-font-size-desktop;

    &--xs {
      font-size: $h4-font-size-desktop;
    }

    &--sm {
      font-size: $h2-font-size-desktop;
    }

    &--lg {
      font-size: 4rem;
    }
  }
}

.section-description {
  max-width: 480px;
  margin: 0 auto 2rem;
  font-size: $font-size-sm;
}

.article-title {
  margin-bottom: 2rem;
  font-size: $h3-font-size;

  @include media-breakpoint-up(lg) {
    font-size: $h3-font-size-desktop;
  }
}

.section-subtitle {
  font-size: $font-size-sm;
  font-weight: $font-weight-bold;
  color: $primary;

  @include media-breakpoint-up(lg) {
    font-size: $font-size-base;
  }
}

.entry-title {
  font-size: $h5-font-size;

  @include media-breakpoint-up(lg) {
    font-size: $h4-font-size-desktop;
  }
}

.paragraph-title {
  font-family: $headings-font-family;
  font-size: $font-size-base;
  font-weight: $font-weight-bold;
}

.title-number {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
  width: 2rem;
  height: 2rem;
  margin-right: .5rem;
  font-size: 1rem;
  font-family: $headings-font-family;
  font-weight: $font-weight-bold;
  color: $light;
  background: #332f4a;
  border-radius: 100%;
}

/*
  Links
*/

.link {
  text-decoration: underline;
  color: $secondary;

  &:hover {
    text-decoration: none;
  }
}

.link-muted {
  color: $body-color;
  opacity: 0.6;

  &:hover {
    color: inherit;
    opacity: 1;
  }
}

/*
  Badge
*/

.badge {
  padding-bottom: 0.3rem;
}

/*
  Lists
*/

.list-dotted {
  padding-left: 0;
  list-style-type: none;

  li {
    position: relative;
    padding-left: 1rem;
    line-height: 1.25;

    &:not(:last-child) {
      margin-bottom: 1rem;
    }

    &:before {
      position: absolute;
      content: '';
      top: 0.425em;
      left: 0;
      width: 0.5rem;
      height: 0.5rem;
      background-color: $primary;
      border-radius: 100%;
    }
  }
}

.list-symbol-post {
  padding: 0;
  font-size: 0.75rem;
  line-height: 1.25;
  list-style-type: none;

  li:not(:last-child) {
    border-bottom: 1px solid $border-color;
  }

  a {
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    min-height: 50px;
    padding: 0.25rem 0 0.25rem 2.5rem;

    &,
    &:hover {
      color: $body-color;
    }

    strong {
      display: block;
      font-size: $font-size-sm;
      transition: $animation-default;
    }

    figure {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 50%;
      left: 0;
      width: 2rem;
      height: 2rem;
      margin: 0;
      transform: translate(0, -50%);

      img {
        display: block;
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
      }
    }

    &:hover {
      strong {
        color: $primary;
      }
    }

    > svg {
      position: absolute;
      top: 50%;
      right: 1rem;
      width: 1rem;
      height: 1rem;
      fill: $body-color;
      opacity: 0.4;
      transform: translate(0, -50%);
    }
  }
}

.list-check {
  padding: 0;
  list-style-type: none;

  li {
    display: flex;
    align-items: center;
    margin-bottom: 0.25rem;

    &:last-child {
      margin-bottom: 0;
    }

    svg {
      display: block;
      width: 0.6875rem;
      height: 0.6875rem;
      margin-right: 0.25rem;
      fill: $success;
    }
  }

  @include media-breakpoint-up(lg) {
    &--lg {
      font-size: $font-size-lg;

      li {
        margin-bottom: 0.5rem;

        svg {
          width: 1.125rem;
          height: 1.125rem;
          margin-right: 0.5rem;
        }
      }
    }
  }
}

.list-nav {
  margin: 0;
  padding: 0;
  list-style-type: none;

  > li {
    > a {
      display: flex;
      align-items: center;
      min-height: 42px;
      padding: 0.25rem 0.75rem;
      font-size: 0.75rem;
      font-weight: $font-weight-bolder;
      border: 1px solid transparent;
      color: $dark;
      border-radius: $border-radius;
      opacity: 0.7;

      svg {
        display: block;
        width: 1rem;
        height: 1rem;
        margin-right: 0.5rem;
      }

      &:hover {
        border-color: $border-color;
        color: $dark;
        background: #f8f9fa;
        opacity: 1;
      }
    }
  }

  .separator-top {
    position: relative;
    padding-top: 15px;

    &:before {
      position: absolute;
      content: '';
      top: 7px;
      right: 0;
      left: 0;
      border-top: 1px solid $border-color;
    }
  }
}

.list-with-icon {
  padding: 0;
  font-size: 0.8125rem;
  list-style-type: none;

  li,
  &__item {
    position: relative;
    padding-left: 1.5rem;

    svg {
      position: absolute;
      top: 50%;
      left: 0;
      width: 1rem;
      height: 1rem;
      transform: translate(0, -50%);
    }

    a {
      display: block;
      color: $secondary;

      svg {
        fill: $secondary;
      }

      &:hover {
        opacity: 0.7;
      }
    }
  }

  &--inline {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    li {
      margin: 0 1rem 1rem;
    }
  }
}

.list-icons {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 0;
  list-style-type: none;

  li:not(:last-child) {
    margin-right: 0.75rem;
  }

  svg {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    fill: $light;
  }

  @include media-breakpoint-up(lg) {
    svg {
      width: 2rem;
      height: 2rem;
    }
  }
}

/*
  Pagination
*/

.pagination {
  font-size: $font-size-sm;
  font-weight: $font-weight-bold;

  .page-link {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 2.5rem;
    min-width: 2.5rem;
    text-align: center;
    border-radius: $border-radius-sm;

    svg {
      width: 0.6875rem;
      height: 0.6875rem;
      fill: $pagination-color;
    }
  }

  .page-item {
    margin-bottom: 0.75rem;

    &--arrow {
      margin-left: 0.75rem;
      margin-right: 0.75rem;

      .page-link {
        border-color: $pagination-color;
      }
    }

    &:first-child,
    &:last-child {
      .page-link {
        border-radius: $border-radius-sm;
      }
    }
  }
}

/*
  Figure responsive
*/

.figure-responsive {
  @include responsive-img(100%);
}

/*
  Price
*/

.price {
  position: relative;
  line-height: 1;

  &__discount {
    display: inline-flex;
    align-items: center;
    margin-bottom: 0.5rem;
    white-space: nowrap;
    font-family: $headings-font-family;
    font-weight: $font-weight-bold;
    font-size: 0.75rem;
    background-color: $c-gray;
    border-radius: $border-radius-sm;

    span {
      display: block;
      padding: 0.375rem;
      color: $light;
      background-color: $primary;
      border-radius: $border-radius-sm;
    }

    del {
      padding: 0 0.5rem;
      opacity: 0.6;
    }
  }

  &__value {
    display: block;
    font-size: 1.25rem;
    font-weight: $font-weight-bolder;
    font-family: $headings-font-family;
    text-decoration: none;
  }

  &__status {
    display: flex;
    align-items: center;
    font-size: 0.75rem;
    margin-top: 0.25rem;

    svg {
      display: block;
      width: 10px;
      height: 10px;
      margin-right: 0.25rem;
    }
  }
}

/*
  Tag
*/

.tag {
  display: inline-flex;
  align-items: center;
  padding: 0.275rem 0.5rem 0.225rem;
  font-size: 0.625rem;
  font-weight: $font-weight-bold;
  font-family: $headings-font-family;
  border: 1px solid $secondary;
  color: $secondary;
  border-radius: $border-radius-sm;

  svg {
    display: block;
    width: 0.75rem;
    height: 0.75rem;
    margin-right: 0.25rem;
    fill: $secondary;
  }
}

/*
  Breadcrumb
*/

.breadcrumb {
  &-item::before {
    opacity: 0.6;
  }

  a {
    font-weight: $font-weight-bolder;
    color: $dark;

    &:hover {
      color: $dark;
    }
  }

  span {
    opacity: 0.6;
    font-weight: $font-weight-normal;
  }

  &--light {
    font-weight: $font-weight-normal;
    color: $light;

    a {
      color: $light;
    }
  }
}

/*
  Gallery
*/

.gallery-item {
  display: block;
  overflow: hidden;
  border-radius: $border-radius;
  transition: $animation-default;

  @include responsive-img(50%);

  &:hover {
    transform: translate(0, -5px);
    opacity: 0.95;
  }
}

/*
  ID number
*/

.id-number {
  padding: 0.344rem 0.5rem;
  background-color: $c-gray;
  border-radius: 2px;
}

/*
  Color icon
*/

.color-icon {
  display: block;
  width: 28px;
  min-width: 28px;
  height: 28px;
  border-radius: 100%;

  &--blue {
    background-image: linear-gradient(225deg, #00e0fe 0%, #073797 100%);
  }

  &--black {
    background-image: linear-gradient(225deg, #666 0%, #060606 100%);
  }

  &--white {
    background-image: linear-gradient(225deg, #fcfcfc 0%, #eee 100%);
  }
}

/*
  Quote
*/

blockquote {
  padding: 0 20px;
  font-family: $headings-font-family;
  font-size: $font-size-base;
  font-weight: $font-weight-bold;
  line-height: 1.2;
  background: url('../img/quote-bottom.png') left 0 bottom 40px no-repeat,
    url('../img/quote-top.png') right top no-repeat;
  background-size: auto 16px;

  cite {
    display: block;
    font-size: 0.9375rem;
    font-weight: $font-weight-normal;
    font-style: normal;
    opacity: 0.7;

    &:before {
      content: '- ';
    }
  }

  @include media-breakpoint-up(lg) {
    padding: 0 40px;
    font-size: $font-size-lg-desktop;
    background-size: auto 32px;
  }
}

/*
  Rating
*/

.rating {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  font-size: 0.8125rem;

  &__logo {
    height: 1.5rem;
    margin-right: 1rem;

    img {
      display: block;
      height: 100%;
    }
  }

  &__stars {
    display: flex;
    align-items: center;
    font-weight: $font-weight-bold;
    font-family: $headings-font-family;
    line-height: 1;

    svg {
      display: block;
      width: 1rem;
      height: 1rem;
      margin-right: 0.125rem;
      fill: #fd9843;

      &.disabled {
        fill: #ced4da;
      }
    }

    span {
      display: inline-block;
      padding-left: 0.125rem;
    }
  }

  &__count {
    position: relative;
    padding-left: 1rem;
    color: $secondary;

    &:before {
      position: absolute;
      content: '';
      top: 50%;
      left: 0.5rem;
      width: 3px;
      height: 3px;
      margin: -1px 0 0 -1px;
      background: $border-color;
      border-radius: 100%;
    }
  }

  &--lg {
    align-items: flex-start;
    flex-direction: column;
    font-family: $headings-font-family;

    .rating {
      &__stars {
        order: 2;

        svg {
          width: 2rem;
          height: 2rem;
          margin-right: 0.25rem;
        }
      }

      &__count {
        order: 1;
        padding-left: 0;

        &:before {
          display: none;
        }

        span {
          font-size: 3rem;
          line-height: 1;
          color: $body-color;
        }
      }
    }
  }
}

/*
  Table
*/

.table {
  font-size: $font-size-sm;

  th,
  td {
    padding: 0.5rem 1rem;
  }
}

/*
  Rating results
*/

.rating-results {
  padding: 0;
  font-size: $font-size-sm;
  list-style-type: none;
  white-space: nowrap;

  li {
    display: flex;
    align-items: center;
    margin-bottom: 0.75rem;
  }

  &__title {
    flex: 2 0 0;
    text-align: right;
  }

  &__bar {
    position: relative;
    overflow: hidden;
    flex: 5 0 0;
    height: 20px;
    margin: 0 0.75rem 0 3rem;
    background: $c-gray;
    border-radius: 20px;

    span {
      display: block;
      height: 100%;
      background: $success;
    }
  }

  &__count {
    flex: 1 0 0;
    opacity: 0.38;
  }
}

/*
  Separators
*/

.text-separator {
  overflow: hidden;
  text-align: center;
  font-size: $font-size-sm;
  color: transparentize($body-color, 0.2);

  span {
    position: relative;
    display: inline-block;
    padding: 0.5rem 1rem;

    &:before,
    &:after {
      position: absolute;
      content: '';
      top: 50%;
      width: 2000px;
      border-bottom: 1px solid $border-color;
    }

    &:before {
      right: 100%;
    }

    &:after {
      left: 100%;
    }
  }
}
