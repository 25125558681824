/*
  Top Bar
*/

.topbar {
  position: fixed;
  display: flex;
  align-items: center;
  overflow: hidden;
  z-index: 1100;
  top: 0;
  right: 0;
  left: 0;
  height: $top-bar-height;
  text-align: center;
  font-size: 0.75rem;
  color: $light;
  background: #282C2F;

  .text-slider {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 0;
  }

  @include media-breakpoint-up(xl) {
    height: $top-bar-height-desktop;
  }
}

/*
  Feture Bar
*/

.featurebar {
  height: 48px;
  border-bottom: 1px solid $border-color;

  .container,
  .text-slider {
    height: 100%;
  }

  svg {
    opacity: 0.7;
  }

  @include media-breakpoint-up(lg) {
    height: 56px;

    .swiper .swiper-wrapper {
      flex-direction: row;
      justify-content: space-between;
      transform: none !important;

      .swiper-slide {
        width: auto;
        height: auto !important;
        padding: 0;

        &-duplicate {
          display: none;
        }
      }
    }
  }
}

/*
  Header
*/

.header {
  position: fixed;
  display: flex;
  align-items: center;
  z-index: 1101;
  top: 0;
  right: 0;
  left: 0;
  border-bottom: 1px solid $border-color;
  height: $header-height;
  background: $light;

  > .container {
    display: flex;
    align-items: center;
  }

  &__logo {
    display: block;
    height: 3rem;

    img,
    svg {
      display: block;
      width: auto;
      height: 100%;
    }

    svg * {
      transition: fill $animation-time $animation-type;
    }
  }

  &__back {
    display: flex;
    align-items: center;
    line-height: 1.3;
    font-size: 0.75rem;
    text-align: left;
    color: $dark;

    svg {
      display: block;
      margin-right: 0.25rem;
      width: 1.25rem;
      min-width: 1.25rem;
      height: 1.25rem;
      fill: $dark;
    }

    @include media-breakpoint-down(sm) {
      max-width: 100px;
    }
  }

  &__btn {
    position: relative;
    display: block;
    min-width: 2.875rem;
    padding-top: 0.125rem;
    text-align: center;
    font-size: 0.75rem;
    line-height: 1.3;
    font-family: $font-family-base;
    color: $dark;

    svg {
      display: block;
      width: 1.25rem;
      height: 1.25rem;
      margin: 0 auto;
      fill: $dark;
      transition: fill $animation-time $animation-type;
    }

    .status {
      position: absolute;
      display: block;
      top: 0;
      left: 1.5rem;
      width: 1rem;
      height: 1rem;
      text-align: center;
      line-height: 1rem;
      font-size: 0.5625rem;
      color: $light;
      background-color: $primary;
      border-radius: 100%;
    }

    &:hover {
      color: transparentize($dark, 0.3);
    }

    @include media-breakpoint-up(xl) {
      .status {
        top: 1.875rem;
      }
    }
  }

  &__dropdown {
    &__menu {
      display: none;
    }
  }

  &--basic {
    > .container {
      align-items: center;
    }

    .header__title {
      margin: 0;
      font-size: $h5-font-size;
      color: $light;
    }
  }

  @include media-breakpoint-down(xl) {
    &__logo {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  @include media-breakpoint-up(xl) {
    height: $header-height-desktop;

    &__logo {
      height: 4rem;
      margin-right: auto;
    }

    &__dropdown {
      position: relative;
      margin-left: .5rem;

      &__menu {
        position: absolute;
        display: block;
        visibility: hidden;
        top: 100%;
        left: 50%;
        padding-top: 20px;
        font-size: 0.75rem;
        transform: translate(-50%, 0);
        transition: $animation-default;
        opacity: 0;

        &__inner {
          position: relative;
          min-width: 260px;
          background: $light;
          border-radius: $border-radius;

          &:before {
            position: absolute;
            content: '';
            top: 0;
            left: 50%;
            margin: -12px 0 0 -12px;
            border-bottom: 12px solid $light;
            border-right: 12px solid transparent;
            border-left: 12px solid transparent;
          }
        }
      }

      &__body {
        padding: 1rem;
      }

      &__footer {
        padding: 1rem;
        border-top: 1px solid $border-color;
      }

      .list-nav {
        .separator-top:before {
          left: -1rem;
          right: -1rem;
        }
      }

      &:hover {
        .header__dropdown__menu {
          visibility: visible;
          opacity: 1;
        }
      }
    }

    &__btn {
      display: flex;
      align-items: center;
      height: $header-height-desktop;
      padding-top: 0;

      svg {
        width: 1.5rem;
        height: 1.5rem;
      }
    }

    .inline-form {
      margin-right: 30px;
      flex: 1;

      .form-control {
        height: 42px;
        border-color: $border-color;
        background-color: #f8f9fa;
        border-radius: 2rem;

        &:focus {
          box-shadow: none;
        }
      }

      > .btn svg {
        width: 1rem;
        height: 1rem;
        fill: $dark;
      }
    }

    &__menu {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 50%;
      left: 10%;
      width: 80%;
      margin-top: -.75rem;
      font-family: $headings-font-family;

      > ul {
        display: flex;
        align-items: center;
        margin: 0;
        padding: 0;
        list-style-type: none;

        > li {
          margin-left: 1.125rem;
          margin-right: 1.125rem;

          > a {
            display: flex;
            align-items: center;
            font-size: $font-size-sm;
            font-weight: $font-weight-bold;
            white-space: nowrap;
            color: $dark;

            svg {
              display: inline-block;
              width: 1rem;
              height: 1rem;
              margin-left: 0.75rem;
              fill: $dark;

              &:first-child {
                margin-right: 0.75rem;
              }

              &:last-child {
                width: 0.75rem;
                height: 0.75rem;
                margin-left: 1rem;
              }
            }
          }

          &:hover,
          &.active {
            > a {
              color: $primary;
            }
          }

          &.parent--active {
            > .megamenu {
              visibility: visible;
              opacity: 1;
            }
          }

          li {
            > a > .avatar {
              width: 48px;
              height: 48px;
              padding-bottom: 0;
            }
          }
        }
      }

      .megamenu {
        position: fixed;
        overflow: hidden;
        visibility: hidden;
        z-index: 1;
        left: 0;
        top: calc(#{$header-height-desktop} + #{$top-bar-height-desktop});
        width: 100%;
        padding: 2rem 0 1.75rem;
        background: #F7F7F7;
        opacity: 0;
        transition: $animation-default;

        &__close {
          position: absolute;
          top: 1.5rem;
          right: 1.5rem;
          width: 1rem;
          height: 1rem;
          opacity: 0.5;

          svg {
            display: block;
            width: 100%;
            height: 100%;
          }

          &:hover {
            opacity: 0.8;
          }
        }

        &__title {
          margin-bottom: 1rem;
          font-size: 1.125rem;
        }

        hr {
          margin-left: -40px;
          margin-right: -40px;
        }

        &__item {
          position: relative;
          display: block;
          padding: 1rem;
          border: 1px solid transparent;
          text-align: center;
          font-size: $font-size-sm;
          font-weight: $font-weight-bold;
          color: $dark;
          border-radius: $border-radius;
          transition: $animation-default;

          figure {
            height: 5.625rem;
            margin-bottom: 0.75rem;

            img {
              display: block;
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }

          a {
            color: $body-color;

            &:hover {
              text-decoration: underline;
            }
          }

          > a {
            font-size: $font-size-base;
            font-weight: $font-weight-bold;
          }

          &:hover {
            border-color: $border-color;
            color: $body-color;
            background: #f8f9fa;
          }

          ul {
            padding: 0;
            font-size: 0.8125rem;
            font-weight: $font-weight-normal;
            line-height: 1.33333;
            list-style-type: none;
          }
        }

        .category-box {
          padding: 1.125rem 0.5rem;
          border: 1px solid transparent;
          box-shadow: none;

          figure {
            height: 90px;
            margin-bottom: 0.75rem;
          }

          &--figure {
            figure {
              height: auto;
            }
          }

          &__title {
            font-size: $font-size-base;
          }

          &:hover {
            border-color: $border-color;
            color: $body-color;
            background: #f8f9fa;
          }
        }
      }
    }

    &--basic {
      .header {
        &__logo {
          height: 58px;
        }

        &__title {
          font-size: $h5-font-size-desktop;
        }
      }
    }
  }
}

.show-top-bar {
  .header {
    top: $top-bar-height;

    @include media-breakpoint-up(xl) {
      top: $top-bar-height-desktop;
    }
  }
}

.header-static {
  .header {
    position: relative;
    top: 0;
  }
}

/*
  Navigation
*/

@include media-breakpoint-down(xl) {
  .menu-btn {
    position: relative;
    display: block;
    width: 34px;
    padding-top: 1.6875rem;

    &__line {
      position: absolute;
      top: 14px;
      right: 13px;
      left: 13px;
      height: 2px;
      margin-top: -1px;
      background: $dark;
      transition: $animation-default;

      &:nth-child(1) {
        transform: translate3d(0, -7px, 0);
      }

      &:nth-child(3) {
        transform: translate3d(0, 7px, 0);
      }
    }
  }

  .mobile-menu {
    &,
    &__submenu {
      position: fixed;
      overflow: auto;
      -webkit-overflow-scrolling: touch;
      visibility: hidden;
      z-index: 1100;
      top: $header-height;
      right: 0;
      bottom: 0;
      left: 0;
      height: calc(100% - #{$header-height});
      padding: 25px 20px;
      font-size: $font-size-sm;
      background: $light;
      opacity: 0;
      transition: $animation-default;
    }

    &__submenu {
      transform: translate(-25%, 0);
    }

    ul {
      padding: 0;
      list-style-type: none;
    }

    li.active {
      > .mobile-menu__submenu {
        visibility: visible;
        opacity: 1;
        transform: translate(0, 0);
      }
    }

    .list-nav {
      > li {
        margin-bottom: 15px;

        > a {
          position: relative;
          height: 48px;
          padding-left: 3rem;
          font-size: inherit;
          border-color: $border-color;
          background: #f8f9fa;

          svg {
            position: absolute;
            top: 50%;
            left: 1rem;
            transform: translate(0, -50%);
          }
        }
      }

      a {
        opacity: 1;
      }
    }

    .list-symbol-post {
      margin: 8px 0 25px;

      li {
        margin-top: 4px;

        &:not(:last-child) {
          border-bottom: 0;
        }

        a {
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          min-height: 56px;
          padding-left: 3rem;
          font-size: inherit;
          background: #f8f9fa;
          border-radius: $border-radius;

          figure {
            left: 0.5rem;
          }

          &[data-submenu-back] {
            svg {
              right: auto;
              left: 0.75rem;
            }
          }
        }
      }
    }
  }

  .show-top-bar {
    .mobile-menu,
    .mobile-menu__submenu {
      top: $header-height + $top-bar-height;
      height: calc(100% - (#{$header-height} + #{$top-bar-height}));
    }
  }

  .mobile-menu-active {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    .menu-btn {
      span {
        &:nth-child(1) {
          transform: rotate(45deg);
        }

        &:nth-child(2) {
          opacity: 0;
        }

        &:nth-child(3) {
          transform: rotate(-45deg);
        }
      }
    }

    .mobile-menu {
      visibility: visible;
      opacity: 1;
    }
  }
}

/*
  Mobile Search
*/

.mobile-search {
  position: absolute;
  display: flex;
  align-items: center;
  visibility: hidden;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 0 20px;
  background: #f8f9fa;
  opacity: 0;
  transition: $animation-default;

  .inline-form {
    width: 100%;

    .form-control {
      background: $light;
      border-radius: 2rem;
    }
  }

  [data-search-close] {
    width: 1.125rem;
    height: 1.125rem;
    margin-left: 20px;

    svg {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  &--active {
    visibility: visible;
    opacity: 1;
  }
}

/*
  Footer
*/

.footer {
  position: relative;
  padding-top: 2rem;
  font-size: $font-size-sm;
  color: $light;
  background-color: $dark;

  > * {
    position: relative;
    z-index: 2;
  }

  a {
    color: transparentize($light, .2);

    &:hover {
      color: transparentize($light, 0.5);
    }
  }

  .list-with-icon {
    svg {
      fill: $primary;
    }
  }

  &__logo {
    margin-bottom: 1.5rem;
    text-align: center;

    img {
      height: 120px;
    }
  }

  &__headings {
    font-size: 0.75rem;
    line-height: 1.25;

    .text--lg {
      font-size: 1rem;
    }
  }

  &__social {
    .footer__headings {
      text-align: center;
      margin-bottom: 1rem;
    }

    .list-icons {
      justify-content: center;
      margin-bottom: 2rem;
    }
  }

  &__newsletter {
    .footer__headings {
      text-align: center;
      margin-bottom: 1rem;
    }
  }

  &__top {
    margin-bottom: 1.5rem;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid transparentize($light, 0.9);
  }

  &__middle {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1.5rem;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid transparentize($light, 0.9);

    img {
      &:first-child {
        margin-bottom: 1rem;
      }
    }
  }

  @include media-breakpoint-down(lg) {
    &__nav {
      margin-bottom: 1.5rem;

      ul {
        margin-bottom: 0;
        padding: 0;
        list-style-type: none;

        ul {
          display: none;
          margin: 1rem 0 0;

          a {
            display: block;
            padding: 0.5rem 0;
          }
        }

        li.active {
          > ul {
            display: block;
          }

          > span {
            background: #282c2f;

            svg {
              transform: translate3d(0, -50%, 0) rotate(180deg);
            }
          }
        }
      }

      > ul {
        > li {
          margin-bottom: 1rem;

          > span {
            position: relative;
            display: block;
            padding: 0.75rem 2rem 0.75rem 1rem;
            font-weight: $font-weight-bold;
            font-family: $headings-font-family;
            text-align: center;
            cursor: pointer;
            color: $light;
            background: #282c2f;
            border-radius: $btn-border-radius;
            transition: $animation-default;

            svg {
              position: absolute;
              content: '';
              top: 50%;
              right: 1rem;
              width: 1rem;
              height: 1rem;
              transform: translate3d(0, -50%, 0);
              fill: $light;
            }
          }
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    &__logo {
      width: 9.5rem;
      min-width: 9.5rem;
      margin: 0 1.25rem 0 0;
    }

    &__social {
      display: flex;
      align-items: center;

      .footer__headings {
        margin: 0;
        text-align: left;
      }

      .list-icons {
        margin: 0;
      }
    }

    &__newsletter {
      display: flex;
      align-items: center;

      .footer__headings {
        min-width: 200px;
        margin: 0;
        text-align: left;
      }
    }

    &__top,
    &__middle {
      margin-bottom: 2rem;
      padding-bottom: 2rem;
    }

    &__nav {
      position: relative;

      > ul {
        > li {
          position: relative;
          margin-bottom: 1.5rem;

          span {
            font-family: $headings-font-family;
            font-size: $font-size-sm;
            font-weight: $font-weight-bold;
            color: $light;

            > svg {
              display: none;
            }
          }
        }
      }

      ul {
        padding: 0;
        list-style-type: none;

        ul {
          position: relative;
          margin-top: 0.5rem;
          color: transparentize($light, .2);
        }
      }
    }

    &__middle {
      flex-direction: row;
      justify-content: space-between;

      img {
        &:first-child {
          margin: 0;
        }

        &:last-child {
          height: 32px;
        }
      }
    }
  }

  @include media-breakpoint-up(xl) {
    &__logo {
      margin-right: 70px;
    }

    &__newsletter {
      padding-left: 60px;
      background-position: left center;
      background-repeat: no-repeat;
    }
  }
}

.copyright {
  padding: 0.875rem 0;
  border-top: 1px solid transparent;
  font-size: $font-size-sm;
  color: transparentize($light, 0.5);
  background-color: #000;

  p {
    margin-bottom: 0;
  }

  img {
    height: 24px;
  }
}

/*
  Layers
*/

.layer {
  position: fixed;
  visibility: hidden;
  z-index: 1099;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparentize($dark, 0.2);
  opacity: 0;
  transition: $animation-default;
  cursor: pointer;

  &--filter {
    z-index: 1150;
    top: 0;
  }
}

.layer-active {
  .layer {
    visibility: visible;
    opacity: 1;

    &--filter {
      visibility: hidden;
      opacity: 0;
    }
  }
}

.offcanvas-active {
  overflow: hidden;

  .layer {
    z-index: 1199;
  }
}

.filter-active {
  overflow: hidden;

  .layer--filter {
    visibility: visible;
    opacity: 1;
  }
}
