/*
	Grid
*/

.gutter-border {
  margin-right: -1px;
  margin-left: -1px;

  [class*='col'] {
    padding-right: 1px;
    padding-left: 1px;
    padding-bottom: 2px;
  }
}

@include media-breakpoint-only(xs) {
  .expand-xs {
    padding-left: 0;
    padding-right: 0;
  }
}

/*
	Sections
*/

.section {
  position: relative;
  padding-top: $section-pt;
  padding-bottom: $section-pt - $mb-default;

  &.bg--gradient {
    background: rgb(248, 249, 250);
    background: linear-gradient(
      0deg,
      rgba(248, 249, 250, 1) 0%,
      rgba(255, 255, 255, 1) 100%
    );
  }

  @include media-breakpoint-up(lg) {
    padding-top: $section-pt-desktop;
    padding-bottom: $section-pt-desktop - $mb-default;
  }
}

.section-rating {
  &__top {
    border-top: 1px solid $border-color;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 0%,
      #f8f9fa 100%
    );
  }

  &__bottom {
    padding-bottom: 220px;
    background: linear-gradient(
        to bottom,
        transparentize(#e49f63, 0.8) 0%,
        transparentize(#ea7937, 0.8) 100%
      ),
      url('../img/rating-bg.svg') center bottom no-repeat;
  }
}

/*
	Offcanvas
*/

.offcanvas {
  position: fixed;
  overflow: auto;
  z-index: 1200;
  top: 0;
  right: 0;
  bottom: 0;
  width: 90%;
  max-width: 800px;
  background: $light;
  transform: translate(100%, 0);
  transition: transform $animation-time $animation-type;

  &__body {
    padding: 1.5rem;
  }

  [data-offcanvas-close] {
    display: flex;
    align-items: center;
    margin-bottom: 1.25rem;
    font-size: $font-size-sm;
    font-weight: $font-weight-bold;
    font-family: $headings-font-family;
    color: $dark;

    svg {
      width: 1rem;
      height: 1rem;
      margin-right: 0.5rem;
    }
  }

  .list-dotted {
    font-size: $font-size-sm;
  }

  h3 {
    font-size: 1.25rem;
  }

  h4 {
    font-size: 1.125rem;
  }

  .section-subtitle {
    font-size: 1rem;
  }

  .card {
    font-size: $font-size-sm;
    border: 1px solid $border-color;
    box-shadow: none;

    .card-body {
      padding: 1rem;

      p {
        margin-bottom: 1rem;
      }
    }
  }

  .rating-results {
    &__title {
      text-align: left;
    }

    &__bar {
      margin-left: 0;
    }
  }

  &--active {
    transform: translate(0, 0);
  }
}

/*
	Entry
*/

.entry {
  @include media-breakpoint-up(lg) {
    font-size: $font-size-lg;
  }
}

/*
	Article
*/

.article {
  &__title {
    font-size: $font-size-base;
  }

  figure {
    overflow: hidden;
    margin-bottom: $mb-default;
    border-radius: $border-radius-lg;
  }

  @include media-breakpoint-up(lg) {
    &__title {
      font-size: 1.375rem;
    }
  }
}

/*
	Intro
*/

.intro {
  position: relative;
  padding-top: $header-height-desktop;

  > * {
    position: relative;
    z-index: 3;
  }

  &:before {
    position: absolute;
    content: '';
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 50px;
    left: 0;
    background: #010101 url('../img/bg-pattern-2.png') center center no-repeat;
    background-size: cover;
  }

  &:after {
    position: absolute;
    content: '';
    z-index: 2;
    top: 0;
    right: 0;
    left: 0;
    height: 40%;
    background-image: linear-gradient(
      to bottom,
      #010101 0%,
      rgba(0, 0, 0, 0) 100%
    );
    opacity: 0.6;
  }

  &__card {
    margin-left: -$grid-gutter-width / 2;
    margin-right: -$grid-gutter-width / 2;
    padding: 30px 20px 20px;
    background-color: $light;
    border-radius: $border-radius-lg;
    box-shadow: $box-shadow-lg;
  }

  .breadcrumb {
    margin-bottom: 1rem;
  }

  @include media-breakpoint-up(lg) {
    padding-top: $header-height-desktop;

    &__card {
      padding: 5rem 30px 30px;
    }
  }
}

/*
	Hero
*/

.hero {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
	font-size: $font-size-sm;
	line-height: 1.5;
  background-color: #f7f7f7;

  > .container {
    position: relative;
    z-index: 3;
  }

  .section-title {
    font-size: 1.75rem;
    margin-bottom: 0.25rem;
  }

  &__picture {
    overflow: hidden;
    height: 230px;
    margin: 0;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__content {
    padding-top: 1.25rem;
    padding-bottom: 1.5rem;
  }

	&--auto {
		&.hero {
			min-height: auto;

			figure {
				margin: 0 calc(0px - var(--bs-gutter-x) * 0.5) $mb-default;
			}
		}
	}

  @include media-breakpoint-up(lg) {
    align-items: center;
    justify-content: center;
    min-height: 600px;
    font-size: 1.125rem;
    text-align: left;

    &__picture {
      position: absolute;
      z-index: 1;
      top: 0;
      right: 0;
      width: 50%;
      height: 100%;
    }

    .section-title {
      font-size: 3rem;
    }
  }
}

/*
	Carousels
*/

@include media-breakpoint-up(md) {
	.swiper {
		.swiper-slide.col-md-3 {
			width: 25%;
		}
	}
}

.swiper {
	.swiper-button-prev,
	.swiper-button-next {
		width: 2.5rem;
		height: 2.5rem;
		background: #f7f7f7;
		border-radius: 100%;

		&.swiper-button-disabled {
			opacity: 1;

			svg {
				opacity: .2;
			}
		}

		&:after {
			display: none;
		}

		&:hover {
			svg {
				opacity: .6;
			}
		}

		svg {
			width: 1rem;
			height: 1rem;
			transition: opacity $animation-time $animation-type;
		}
	}
}

.swiper-pagination {
  &-bullet {
    opacity: 0.2;

    &-active {
      background: $dark;
      opacity: 1;
    }
  }

  &--white {
    .swiper-pagination-bullet {
      background: $light;
    }
  }
}

.swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 5px;

  .swiper-pagination-bullet {
    margin: 0 2px;
  }
}

.swiper.card-md-carousel,
.swiper.card-lg-carousel {
	overflow: initial;

  .swiper-slide {
    height: auto;
  }

	@include media-breakpoint-down(lg) {
		.swiper-slide {
			min-width: 250px;
			max-width: 250px;
		}
	}

	@include media-breakpoint-up(lg) {
		.swiper-button-prev,
		.swiper-button-next {
			top: auto;
			right: 0;
			left: auto;
			bottom: 100%;
			margin: 0 0 1.5rem 0;
			transform: translate(0, 0);
		}

		.swiper-button-prev {
			margin-right: 3rem;
		}
	}
}

.swiper.figure-carousel {
	overflow: initial;

  .swiper-slide {
    height: auto;
  }

	@include media-breakpoint-down(lg) {
		.swiper-slide {
			min-width: 250px;
			max-width: 250px;
		}
	}

	@include media-breakpoint-up(lg) {
		.swiper-wrapper {
			flex-wrap: wrap;
			width: auto;
			transform: none !important;
			margin-left: -.5rem;
			margin-right: -.5rem;

			.swiper-slide {
				width: 33.333333% !important;
				max-width: none;
				padding-left: .5rem;
				padding-right: .5rem;
				margin: 0 0 1rem !important;
			}
		}
	}
}

.text-slider {
  .swiper {
    &-slide {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      padding: 0 30px;
      line-height: 1;
    }

    &-button-prev,
    &-button-next {
      width: 0.75rem;
      height: 0.75rem;
      margin-top: 0 - 0.75rem / 2;

      &:after {
        display: none;
      }

      svg {
        display: block;
        width: 100%;
        height: 100%;
        fill: $light;
      }
    }
  }
}

.logo-carousel {
  .swiper-slide {
    width: auto;
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 128px;
    height: 64px;
    padding: 16px 0;
    border: 1px solid $border-color;
    background: $light;
    border-radius: $border-radius;

    img {
      display: block;
      height: auto;
      width: auto;
      max-height: 40px;
      max-width: 72px;
    }

    @include media-breakpoint-up(sm) {
      width: 100%;
    }
  }
}

.swiper.picture-carousel {
  overflow: initial;

  .swiper-slide {
    height: auto;
  }
}

/*
	Hero Slider
*/

.hero-slider {
	.swiper-slide {
		height: auto;
	}

	.hero {
		height: 100%;
	}

  .swiper-pagination-bullets {
    top: 190px;
    bottom: auto;
		opacity: .6;

    .swiper-pagination {
      position: absolute;
      z-index: 10px;
      left: 0;
      right: 0;
      bottom: 0;

      &-bullet {
        width: 6px;
        height: 6px;
        margin: 0 5px;
        background: $dark;
        border-radius: 8px;
        opacity: 1;
				background: $light;
        transition: $animation-default;

        &-active {
          transform: scale(1.75);
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
		&__pagination {
			position: absolute;
			display: flex;
			justify-content: flex-start;
			z-index: 10;
			right: 0;
			bottom: 2.5rem;
			left: 0;
		}

    .swiper-pagination {
			position: relative;
			text-align: left;

      &-bullets {
				position: static;
        top: auto;
				width: auto;

        .swiper-pagination-bullet {
					background-color: $dark;
        }
      }
    }
  }
}

/*
	Gallery
*/

.gallery {
  position: relative;
  display: flex;
  overflow: hidden;
  background-color: #F7F7F7;

  &:after {
    display: block;
    content: "";
    padding-bottom: 100%;
  }

  &__zoom {
    position: absolute;
    z-index: 2;
    top: 1.5rem;
    left: 1.5rem;
    width: 1.25rem;
    height: 1.25rem;
    fill: $dark;
  }

  &__picture {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform $animation-time $animation-type;
  }

  &:hover {
    .gallery__picture {
      transform: scale(1.05);
    }
  }

  &--360 {
    .gallery {
      &__picture {
        top: 10%;
        left: 10%;
        width: 80%;
        height: 80%;
        object-fit: contain;
      }
      
      &__360 {
        position: absolute;
        z-index: 2;
        bottom: 2rem;
        left: 50%;
        height: 2.5rem;
        transform: translate(-50%, 0);
      }
    }
  }
}

/*
	Section picture
*/

.section-picture {
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
  min-height: 400px;
  color: $light;

  &__bg {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-size: cover;
    background-position: center center;
  }

  &__inner {
    position: relative;
    z-index: 2;
    width: 100%;
    padding: 2rem 1rem;
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 1) 100%
    );
  }

  blockquote {
    max-width: 950px;
    margin-left: auto;
    margin-right: auto;
  }

  @include media-breakpoint-up(lg) {
    min-height: 720px;

    &__inner {
      padding-bottom: 2.5rem;
      padding-top: 2.5rem;
    }
  }
}

/*
	Product
*/

.product {
  position: sticky;
  bottom: 0;
  font-size: $font-size-sm;

  &__icons {
		position: absolute;
		z-index: 2;
		top: 0;
		right: 0;

		button {
			display: block;
			width: 1.25rem;
			height: 1.25rem;
			margin: 0;
			padding: 0;
			border: 0;
			background: transparent;
			transition: opacity $animation-time $animation-type;

			svg {
				display: block;
				width: 100%;
				height: 100%;
				fill: #495057;
			}

			&:hover {
				opacity: .6;
			}
		}
	}

  &__price {
    margin-bottom: .75rem;
    padding-bottom: .3125rem;
    font-size: 1.5rem;
    font-family: $headings-font-family;
    font-weight: $font-weight-bold;
    border-bottom: 1px solid $border-color;
  }

  &__pictures {
    padding: 1.25rem;
    background: #F7F7F7;

    &__large,
    &__thumbnail {
      position: relative;

      &:after {
        display: block;
        content: "";
        padding-bottom: 75%;
      }

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}

/*
	Product box
*/

.product-box {
	position: relative;
  height: 100%;
	padding: 1rem;
	font-size: $font-size-sm;
	background-color: #f7f7f7;

	figure {
		position: relative;
		margin-bottom: 1rem;

		&:after {
			display: block;
			content: "";
			padding-bottom: 130%;
		}

		a {
			display: block;

			&:hover img {
				transform: scale(1.05);
			}
		}

		img {
			position: absolute;
			display: block;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: contain;
			transition: transform $animation-time $animation-type;
		}
	}

  &__content {
		padding-top: 1rem;
		border-top: 1px solid $border-color;
	}

	&__title {
		margin-bottom: .25rem;
		font-size: $font-size-sm;

		a {
			color: inherit;

			&:hover {
				color: $primary;
			}
		}
	}

	&__footer {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	&__icons {
		position: absolute;
		z-index: 2;
		top: 1rem;
		right: 1rem;

		button {
			display: block;
			width: 1.25rem;
			height: 1.25rem;
			margin: 0;
			padding: 0;
			border: 0;
			background: transparent;
			transition: opacity $animation-time $animation-type;

			svg {
				display: block;
				width: 100%;
				height: 100%;
				fill: #495057;
			}

			&:hover {
				opacity: .6;
			}
		}
	}
}

/*
	Category box
*/

.category-box {
	position: relative;
  display: block;
	overflow: hidden;
  font-weight: $font-weight-bold;
  text-align: center;
	background-color: #f7f7f7;
  transition: $animation-default;

	&:after {
		display: block;
		content: "";
		padding-bottom: 100%;
	}

  img {
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
		transition: transform $animation-time $animation-type;
	}

  &__title {
		position: absolute;
		z-index: 2;
		top: 0;
		right: 0;
		left: 0;
		padding: 1rem .5rem;
    margin-bottom: 0;
    font-size: $font-size-sm;
  }

  @include media-breakpoint-up(lg) {
    &__title {
			padding: 1.5rem;
      font-size: $h4-font-size-desktop;
    }

		&:hover {
			img {
				transform: scale(1.1);
			}
		}
  }
}

/*
	Media box
*/

.media-box {
  position: relative;
  overflow: hidden;
  display: block;
  color: $light;
  border-radius: $border-radius;

  &:before {
    display: block;
    content: '';
    padding-top: 100%;
  }

  &:after {
    position: absolute;
    content: '';
    z-index: 2;
    right: 0;
    bottom: 0;
    left: 0;
    height: 50%;
    background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 0%,
      #000000 100%
    );
  }

  &__bg {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-size: cover;
    background-position: center center;
    transition: $animation-default;
  }

  &__content {
    position: absolute;
    z-index: 3;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1.375rem;

    @include no-last-margin();
  }

  &__title {
    font-size: $h4-font-size;
    color: #fff;
  }

  &__subtitle {
    margin-bottom: 0;
    font-size: $font-size-sm;
    color: mix(black, $primary, 3);
  }

  &__figure {
    position: absolute;
    left: -9999px;
    width: 1px;
    height: 1px;
    opacity: 0;
  }

  &:hover {
    color: $light;

    .media-box__bg {
      transform: scale(1.1);
    }
  }

  @include media-breakpoint-up(sm) {
    &--horizontal {
      &:before {
        padding-top: 39.64%;
      }
    }
  }

  &--vertical {
    &:before {
      padding-top: 125.715%;
    }
  }

  @include media-breakpoint-up(lg) {
    &__subtitle {
      margin-bottom: 1rem;
    }
  }
}

/*
	Tiles
*/

.tile {
  position: relative;
  display: block;
  color: $dark;
  background: #e2e5ea;

  &:before {
    display: block;
    content: '';
    padding-top: 100%;
  }

  &__inner {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 3.5rem 1.25rem 1.25rem;

    img {
      display: block;
      height: 100%;
      margin: 0 auto;
      transition: $animation-default;
    }
  }

  &__title {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    padding: 1.25rem;
    font-size: $h5-font-size;
  }

  &--horizontal {
    &:before {
      padding-top: calc(50% - 1px);
    }
  }

  &:hover,
  &:active {
    color: $dark;
  }

  &:hover {
    img {
      transform: scale(1.05);
    }
  }
}

/*
	Featured
*/

.featured {
  position: relative;
  display: block;
  overflow: hidden;
  background: #e2e5ea;
  border-radius: $border-radius-lg;

  &:before {
    display: block;
    content: '';
    padding-top: 57%;
  }

  &__title {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    left: 0;
    padding: 1.25rem;
    text-align: center;
    font-weight: $font-weight-normal;
    font-size: $h3-font-size;
  }

  &__figure {
    position: absolute;
    overflow: hidden;
    right: 1rem;
    bottom: -45%;
    left: 1rem;
    height: 110%;
    text-align: center;
    transition: transform $animation-time $animation-type;

    img {
      max-width: 60%;
    }
  }

  &,
  &:hover,
  &:focus {
    color: #1d1d1c;
  }

  &:hover {
    .featured__figure {
      transform: translate(0, -20px);
    }
  }

  @include media-breakpoint-up(lg) {
    &:before {
      padding-top: 78%;
    }
  }
}

/*
	Page nav
*/

.page-nav {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 0;
  padding: 0;
  font-size: $font-size-sm;
  list-style-type: none;

  li {
    margin-right: 3em;

    &:last-child {
      margin-right: 0;
    }

    a {
      display: block;
      white-space: nowrap;
      color: $dark;
    }

    &:hover,
    &.active {
      a {
        opacity: 0.54;
        color: $dark;
      }
    }
  }
}

/*
	Search
*/

.search {
  position: absolute;
  //visibility: hidden;
  z-index: 1;
  left: 50%;
  top: 100%;
  width: 100%;
  margin: 32px 0 0;
  padding: 1rem;
  background: $light;
  border-radius: $border-radius-lg;
  transform: translate3d(-50%, 0, 0);
  //opacity: 0;
  transition: $animation-default;

  &:after {
    position: absolute;
    content: '';
    bottom: 100%;
    left: 50%;
    width: 0;
    height: 0;
    margin-left: -12px;
    border-bottom: 12px solid $light;
    border-right: 12px solid transparent;
    border-left: 12px solid transparent;
  }

  &__title {
    margin-bottom: 0.25rem;
    font-size: 0.75rem;
    font-weight: $font-weight-normal;
    font-family: $font-family-base;
    text-transform: uppercase;
    opacity: 0.4;
  }

  ul,
  ol {
    margin-bottom: 1rem;
  }
}

/*
	Search
*/

[data-search].active {
  .search {
    visibility: visible;
    opacity: 1;
  }
}

/*
	Modal
*/

.modal {
  &-body {
    .close {
      position: absolute;
      z-index: 10;
      top: 1rem;
      right: 1rem;
      margin: 0;
      opacity: 1;

      svg {
        display: block;
        width: 1rem;
        height: 1rem;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    &-body {
      padding: 2rem;

      .close {
        top: 1.5rem;
        right: 1.5rem;
      }
    }
  }
}

/*
	Card
*/

.card {
  position: relative;
  z-index: 1;
  box-shadow: $box-shadow;

  &-title {
    font-size: $h4-font-size;
  }

  &-footer {
    border-top: 1px solid $c-gray;
  }

  &__figure {
    overflow: hidden;
    border-radius: $border-radius $border-radius 0 0;

    &__inner {
      transition: $animation-default;

      @include responsive-img(59%);
    }
  }

  &--post {
    height: 100%;
    font-size: $font-size-sm;

    .card {
      height: 100%;

      &-title {
        font-size: $font-size-lg-desktop;
      }

      &-body {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        padding-top: 1.5rem;

        .btn {
          margin-top: auto;
        }

        *:last-child {
          margin-bottom: 0;
        }
      }
    }

    @include media-breakpoint-up(md) {
      &:hover {
        transform: translate3d(0, -10px, 0);

        .btn-outline-primary {
          color: $light;
          background-color: $primary;
        }
      }
    }
  }

  &--nav {
    overflow: hidden;
    border: 1px solid $c-gray;
    box-shadow: none;

    .card-nav {
      padding: 0.75rem;
      border-bottom: 1px solid $c-gray;
      background-color: #f8f9fa;

      .nav {
        font-family: $headings-font-family;
        flex-direction: column;
        font-weight: $font-weight-bold;
        font-size: $font-size-sm;

        a {
          padding: 0.875rem 0.75rem;
          color: transparentize($dark, 0.4);
          border-radius: $border-radius;

          &.active,
          &:hover {
            color: $dark;
            background: $light;
          }
        }
      }
    }

    @include media-breakpoint-up(md) {
      flex-direction: row;

      .card-nav {
        min-width: 256px;
        width: 256px;
        padding: 1rem 0 1rem 1.5rem;
        border-bottom: 0;
        border-right: 1px solid $c-gray;

        .nav {
          margin-right: -1px;

          li {
            margin-bottom: 0.125rem;
          }

          a {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
          }
        }
      }
    }
  }

  &__separator {
    position: relative;

    &:after {
      position: absolute;
      content: '';
      top: -$card-spacer-x;
      right: 0;
      bottom: -$card-spacer-x;
      width: 1px;
      background: $c-gray;
    }

    @include media-breakpoint-down(sm) {
      margin-bottom: 1.5rem;
      padding-bottom: 1.5rem;

      &:after {
        top: auto;
        right: 0;
        bottom: 0;
        left: 0;
        width: auto;
        height: 1px;
      }
    }
  }

  @include media-breakpoint-only(xs) {
    .card-body {
      padding: 1.5rem;
    }

    &--desktop {
      background: transparent;
      border-radius: 0;
      box-shadow: none;

      .card-body {
        padding: 0;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    &--lg-shadow {
      box-shadow: 0 8px 14px rgba(0, 0, 0, 0.12);
    }

    &-title {
      font-size: $h4-font-size-desktop;
    }
  }
}

a.card {
  transition: $animation-default;

  &,
  &:hover {
    color: $body-color;
  }

  &:hover {
    .card {
      &__figure {
        &__inner {
          transform: scale(1.05);
        }
      }
    }
  }
}

/*
	Review
*/

.review {
  &__user {
    padding-top: 0.1em;
    font-size: $font-size-sm;
    font-family: $headings-font-family;
    font-weight: $font-weight-bold;
  }

  &__date {
    position: relative;
    padding-left: 1rem;
    font-size: 0.75rem;
    color: #888;

    &:before {
      position: absolute;
      content: '';
      top: 50%;
      left: 0.5rem;
      width: 3px;
      height: 3px;
      margin: -1px 0 0 -1px;
      background: $border-color;
      border-radius: 100%;
    }
  }
}

/*
	Checkout
*/

.checkout {
  &__header {
    display: flex;
    margin-bottom: 1rem;
    padding-bottom: 0.5rem;
    font-size: $font-size-sm;
    border-bottom: 2px solid $c-gray;

    &__details {
      display: none;
    }

    > *:last-child {
      margin-left: auto;
      text-align: right;
    }
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 0.5rem;
    border-top: 4px solid $c-gray;
    font-weight: $font-weight-bold;
    font-family: $headings-font-family;

    div:first-child {
      opacity: 0.6;
    }

    div:last-child {
      font-size: $h3-font-size;
    }
  }

  &__row {
    display: flex;
    font-size: 0.75rem;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid $c-gray;
  }

  &__figure {
    margin: 0;
    width: 50px;
    min-width: 50px;

    img {
      display: block;
      width: auto;
      height: 48px;
      object-fit: contain;
    }
  }

  &__name {
    p {
      margin-bottom: 0.25rem;
    }
  }

  &__title {
    margin-top: 0.125rem;
    margin-bottom: 0;
    font-size: $font-size-sm;
  }

  &__price {
    margin-left: auto;
    text-align: right;
    font-size: $font-size-sm;
    font-weight: $font-weight-bolder;
  }

  .input-count {
    margin-top: 0.5rem;
  }

  &__body {
    + .checkout__footer {
      margin-top: -1rem;
    }
  }

  @include media-breakpoint-up(md) {
    &__header {
      &__details {
        display: flex;
        width: 100%;
        text-align: center;

        > * {
          flex: 1 0 0;
        }
      }

      > div:first-child {
        width: 50px;
        min-width: 50px;
      }

      > div:last-child {
        width: 100px;
        min-width: 100px;
        text-align: right;
      }
    }

    &__row {
      align-items: center;
      font-size: $font-size-sm-desktop;
    }

    &__name {
      font-size: 0.75rem;
      text-align: left;
    }

    &__title {
      padding-top: 0;
    }

    &__details {
      display: flex;
      align-items: center;
      width: 100%;
      text-align: center;

      div {
        flex: 1 0 0;

        &:nth-child(1) {
          order: 1;
        }

        &:nth-child(2) {
          order: 3;
        }

        &:nth-child(3) {
          order: 2;
        }
      }
    }

    &__price {
      width: 100px;
      min-width: 100px;
    }

    .input-count {
      margin: 0 auto;
    }
  }
}

/*
	Cart
*/

.cart {
  &__row {
    position: relative;
    display: flex;
    padding-top: 1.25rem;
    padding-bottom: 1rem;
    font-size: $font-size-sm;
    border-top: 1px solid $border-color;

    &:last-child {
      border-bottom: 1px solid $border-color;
    }
  }

  &__figure {
    width: 72px;
    margin: 0 15px 0 0;

    img {
      display: block;
      width: 100%;
    }
  }

  &__details {
    width: 100%;

    &__right {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      width: 100%;
    }
  }

  &__name {
    padding-right: 4.5rem;
    margin-bottom: 0.875rem;
    font-size: 0.75rem;

    p {
      margin: 0;
    }
  }

  &__title {
    margin-bottom: 0;
    font-size: $font-size-sm;
  }

  &__quantity {
    font-size: $font-size-sm;

    .input-count {
      margin-bottom: 0.875rem;
    }
  }

  &__price {
    font-weight: $font-weight-bold;
    font-family: $headings-font-family;
    font-size: $font-size-base;
    line-height: 1.5;
  }

  .list-with-icon {
    a {
      color: $dark;
      opacity: 0.4;

      svg {
        fill: $dark;
      }
    }
  }

  @include media-breakpoint-down(lg) {
    .list-with-icon {
      position: absolute;
      top: 1.125rem;
      right: 0;
      display: flex;

      li {
        &:first-child {
          order: 2;
        }

        &:last-child {
          order: 1;
        }
      }

      a {
        width: 1.25rem;
        height: 1.25rem;
      }

      svg {
        position: relative;
        display: block;
        top: 0;
        width: 100%;
        height: 100%;
        transform: none;
      }
    }
  }

  @include media-breakpoint-up(xl) {
    &__figure {
      width: 96px;
      margin-right: 1.5rem;
    }

    &__details {
      display: flex;
      justify-content: space-between;

      &__left {
        display: flex;
        flex-direction: column;
        width: 100%;
      }

      &__right {
        flex-direction: column;
        width: auto;
        text-align: right;
      }
    }

    &__quantity .input-count {
      margin-bottom: 0.25rem;
    }
 
    &__title {
      font-size: $font-size-lg;
    }

    .list-with-icon {
      display: flex;
      margin: auto 0 0;

      li {
        margin-right: 1rem;
      }

      a {
        opacity: 0.6;

        svg {
          opacity: 0.8;
        }
      }
    }
  }
}

/*
	Summary
*/

.summary {
  font-size: $font-size-sm;
  line-height: 1.4;

  ul {
    padding: 0;
    list-style-type: none;

    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-height: 2.75rem;
      border-top: 1px solid $border-color;

      > span:first-child {
        display: flex;
        align-items: center;

        figure {
          width: 24px;
          margin: 0 8px 0 0;
        }
      }

      strong:last-child {
        text-align: right;
      }
    }
  }

  &__header {
    padding-bottom: 0.5rem;
    font-family: $headings-font-family;
    color: #adb5bd;
  }

  &__footer {
    text-align: right;
    padding-top: 0.5rem;
    border-top: 4px solid $c-gray;
    font-weight: $font-weight-bold;
    font-family: $headings-font-family;
    font-size: $h3-font-size;
  }
}

/*
	Coupon
*/

.coupon {
  border-top: 0;
  font-size: $font-size-sm;
  background: transparentize(#ea7937, 0.88);

  &__text {
    p {
      margin-bottom: 0.875rem;
    }
  }

  &__title {
    margin-bottom: 0.25rem;
    font-size: $h4-font-size;
  }

  &__icon {
    width: 24px;
    height: 24px;
    margin-right: 8px;
    fill: $primary;
  }

  @include media-breakpoint-only(xs) {
    &.card-footer {
      border-radius: $border-radius-lg;
    }
  }

  @include media-breakpoint-down(sm) {
    &.card-footer {
      padding: 1rem 1rem 0.125rem;

      .input-group {
        width: auto;
        margin-bottom: 0.875rem;
        margin-left: 32px;
      }
    }
  }

  @include media-breakpoint-up(md) {
    &__icon {
      width: 32px;
      height: 32px;
      margin-right: 12px;
    }
  }

  @include media-breakpoint-up(xl) {
    padding-top: 1.625rem;

    &__icon {
      width: 32px;
      height: 32px;
      margin-right: 12px;
    }
  }
}

/*
	Steps
*/

.step-line {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  font-weight: $font-weight-bolder;
  font-size: 0.625rem;
  line-height: 1.25;
  margin-bottom: 2rem;
  padding: 0;
  list-style-type: none;

  li {
    position: relative;
    overflow: hidden;
    flex: 1 0 0;
    text-align: center;
    color: #adb5bd;

    &:first-child {
      .step-line__num {
        &:before {
          display: none;
        }
      }
    }

    &:last-child {
      .step-line__num {
        &:after {
          display: none;
        }
      }
    }

    a {
      display: block;
      color: inherit;
    }

    &.active {
      color: $success;

      .step-line__num {
        color: $light;
        background-color: $success;

        &::before,
        &::after {
          background-color: $success;
        }
      }
    }

    &.current {
      color: $dark;

      .step-line__num {
        color: $light;
        background-color: $dark;

        &::before {
          background-color: $success;
        }
      }
    }
  }

  &__num {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    width: 1.25rem;
    height: 1.25rem;
    margin: 0 auto 0.5rem;
    font-size: 0.625rem;
    text-align: center;
    font-family: $headings-font-family;
    font-weight: $font-weight-bold;
    color: #adb5bd;
    background-color: #dee2e6;
    border-radius: 100%;

    svg {
      display: block;
      width: 0.625rem;
      height: 0.625rem;
      fill: $light;
    }

    &:before,
    &:after {
      position: absolute;
      content: '';
      top: 0.625rem;
      width: 1000px;
      height: 1px;
      background: #dee2e6;
    }

    &:before {
      right: 100%;
    }

    &:after {
      left: 100%;
    }
  }

  @include media-breakpoint-up(md) {
    margin-bottom: 2.5rem;
    font-size: 0.75rem;
  }
}

/*
	Filter
*/

.filter {
  font-size: $font-size-sm;

  fieldset {
    margin-bottom: 1rem;
  }

  &__title {
    font-size: inherit;
  }

  @include media-breakpoint-down(sm) {
    position: fixed;
    visibility: hidden;
    z-index: 1200;
    top: 50px;
    right: 0;
    bottom: 0;
    left: 0;
    background: $body-bg;
    border-radius: $border-radius-lg $border-radius-lg 0 0;
    opacity: 0;
    transform: translate(0, 50px);
    transition: $animation-default;

    &__close {
      position: absolute;
      top: 1rem;
      right: ($grid-gutter-width / 2);
      width: 1rem;
      height: 1rem;
      opacity: 0.3;

      svg {
        display: block;
        width: 100%;
        height: 100%;
      }
    }

    &__body {
      overflow: auto;
      height: 100%;
      padding: 1.75rem $grid-gutter-width 80px;
    }

    &__footer {
      position: fixed;
      z-index: 1;
      right: 0;
      bottom: 0;
      left: 0;
      border-top: 1px solid $border-color;
      padding: 1rem $grid-gutter-width;
      background: $body-bg;
    }

    &-active {
      .filter {
        visibility: visible;
        opacity: 1;
        transform: translate(0, 0);
      }
    }
  }

  @include media-breakpoint-up(md) {
    &__panel {
      margin-bottom: 1rem;
      padding: 1rem;
      border: 1px solid $c-gray;
      background-color: $light;
      border-radius: $border-radius-lg;

      &__title {
        margin: -1rem -1rem 1rem;
        padding: 1rem;
        border-bottom: 1px solid $c-gray;
        font-size: $font-size-base;
      }

      fieldset {
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

/*
	Filter Categories
*/

.categories {
  font-size: $font-size-sm;
  line-height: 1.25;
  font-family: $headings-font-family;
  font-weight: $font-weight-bold;

  ul {
    position: relative;
    margin: 0.5rem 0 0;
    padding: 0 0 0 0.6875rem;
    border-left: 1px solid $border-color;
    list-style-type: none;

    li:not(:last-child) {
      margin-bottom: 0.5rem;
    }

    ul {
      margin-top: 0.75rem;
      font-weight: $font-weight-normal;

      li:not(:last-child),
      li:last-child {
        margin-bottom: 0.75rem;
      }
    }
  }

  a {
    color: $body-color;
  }

  > a {
    display: flex;
    align-items: center;
    margin-left: -0.125rem;
    color: $secondary;

    svg {
      display: block;
      width: 0.625rem;
      height: 0.625rem;
      margin-right: 0.25rem;
      fill: $secondary;
    }
  }
}

/*
	Accordion
*/

.accordion {
  &-item {
    border-bottom-width: 1px;
  }

  &-button {
    font-weight: $font-weight-bolder;
  }

  &-body {
    padding-top: 0;
    font-size: $font-size-sm;

    > *:last-child {
      margin-bottom: 0;
    }
  }

  .table {
    margin: 0;

    th,
    td {
      background-color: #f8f9fa;
    }

    tr {
      &:nth-child(even) {
        th,
        td {
          background-color: transparent;
        }
      }

      &:first-child {
        > td,
        > th {
          border-top: 1px solid $border-color;
        }
      }

      &:last-child {
        > td,
        > th {
          border-bottom: 0;
        }
      }
    }
  }

  &--plus {
    .accordion {
      &-button {
        &:before {
          position: absolute;
          content: "";
          top: 50%;
          right: 6px;
          width: 3px;
          height: 15px;
          background: $dark;
          border-radius: 3px;
          transform: translate(0, -8px);
          opacity: 1;
        }

        &:after {
          width: 15px;
          height: 3px;
          background: $dark;
          border-radius: 3px;
          transform: none;
        }

        &[aria-expanded="true"]:before {
          opacity: 0;
        }
      }
    }
  }

  &--panel {
    .accordion {
      &-item {
        margin-bottom: .5rem;
        border: 1px solid $border-color;
        border-radius: $border-radius;
      }

      &-button,
      &-body {
        padding-left: 1rem;
        padding-right: 1rem;
      }

      &-button {
        font-size: $font-size-sm;

        &:before,
        &:after {
          background-color: #d8d8d8;
        }

        &:before {
          right: calc(6px + 1rem);
        }
      }
    }
  }
}

/*
	Panel
*/

.panel {
  padding: 2rem;
  background: #f8f9fa;
  border-radius: $border-radius-lg;

  @include media-breakpoint-only(xs) {
    padding-left: 0;
    padding-right: 0;
    border-radius: 0;
  }
}

/*
  Page Figure
*/

.page-figure {
  overflow: hidden;
  height: 200px;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: $border-radius-lg;

  @include media-breakpoint-only(xs) {
    height: 170px;
    margin-right: 0 - ($grid-gutter-width / 2);
    margin-left: 0 - ($grid-gutter-width / 2);
    border-radius: 0;
  }
}

/*
	Article Figure
*/

.article-figure {
	position: relative;
	height: 520px;

	img {
		display: block;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	.btn {
		position: absolute;
		left: 50%;
		bottom: 3rem;
		white-space: nowrap;
		transform: translate(-50%, 0);
	}

	@include media-breakpoint-up(lg) {
		height: 550px;

		.btn {
			left: 3rem;
			transform: translate(0, 0);
		}
	}
}

/*
	Figure Box
*/

.figure-box {
  display: block;

	figure {
		position: relative;
		overflow: hidden;
		margin-bottom: 1.5rem;

		img {
			position: absolute;
			display: block;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
			transition: transform $animation-time $animation-type;
		}

		&:after {
			display: block;
			content: "";
			padding-bottom: 58%;
		}
	}

	&__title {
		margin-bottom: 0;
		font-size: $font-size-sm;
	}

  &--lg {
    figure {
      &:after {
        padding-bottom: 100%;
      }
    }
  }
}

a.figure-box:hover {
	img {
    transform: scale(1.1);
  }
}