
span[data-animate],
span[data-animate-delay],
span[data-animate-group] {
	display: inline-block;
}

.scroll-animation {
	animation-duration: .8s;
	animation-fill-mode: both;
    animation-timing-function: cubic-bezier(.19, 1, .22, 1)
}

[data-animate-text] {
	> span {
		display: inline-block;
		overflow: hidden;
		margin-bottom: -.2em;
	}
}

	[data-animate-text] > span span {
		display: block;
		animation-timing-function: cubic-bezier(.075, .82, .165, 1);
		animation-duration: .8s;
	}

.scale {
	animation-name: scale;
}

.fadeInUp {
	animation-name: fadeInUp;
}

.wordFadeInUp {
	animation-name: wordFadeInUp;
}

.menuFloat {
	animation-duration: .3s;
	animation-fill-mode: both;
	animation-name: menuFloat;
}

@keyframes scale {
	0% {
		opacity: 0;
		transform: scale(.8);
	}
	100% {
		opacity: 1;
		transform: scale(1);
	}
}

@keyframes fadeInUp {
	0% {
		opacity: 0;
		transform: translate3d(0, 50px, 0);
	}
	100% {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
}

@keyframes menuFloat {
	0% {
		opacity: 0;
		transform: translate3d(40px, 0, 0);
	}
	100% {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
}

@keyframes wordFadeInUp {
	0% {
		opacity: 0;
		transform: translate3d(0, 110%, 0);
	}
	100% {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
}

@keyframes heart-beats {
    0% {
        transform: scale(1);
    }
    7% {
        transform: scale(1.2);
    }
    12% {
        transform: scale(1.1);
    }
    20% {
        transform: scale(1.3);
    }
    60% {
        transform: scale(1);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes showSuccess {
    from {
        transform: scale(0);
    }
    to {
        transform: scale(1);
    }
}

@keyframes tipInPlace {
    from {
        width: 0em;
        top: 3.5em;
        left: 2em;
    }
    to {
        width: 2.8em;
        top: 4.3em;
        left: 1.4em;
        visibility: visible;
    }
}

@keyframes longInPlace {
    from {
        width: 0em;
        top: 5.1em;
        left: 3.2em;
    }
    to {
        width: 4.4em;
        top: 3.80em;
        left: 2.55em;
        visibility: visible;
    }
}
