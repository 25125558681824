@mixin responsive-img($ratio: 50%) {
    background-position: center center;
    background-size: cover;

    &:before {
        display: block;
        content: "";
        padding-bottom: $ratio;
    }

    img {
        position: absolute;
        left: -9999px;
        width: 1px;
        height: 1px;
        opacity: 0;
    }
}

@mixin no-last-margin() {
    > *:last-child {
        margin-bottom: 0;
    }
}